<template>
  <div>
    <!-- <b-overlay
      spinner-variant="danger"
      variant="dark"
      z-index="900"
      fixed
      :show="generatingPdf"
      no-wrap
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="SalesReport"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-3">
        <sales-report-export :items="exportItems" />
      </section>
    </vue-html2pdf> -->

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <!-- <div class="d-flex justify-content-end mb-4">
          <b-button class="mr-3 mb-5" variant="info" @click="generatePDF">{{
            $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_PDF")
          }}</b-button>
        </div> -->
          <!-- <b-button class="mb-4" variant="primary" @click="preparePosLink()">
            {{ $t("MENU.NEW") }}
            {{ $t("PAGES.SALES_MANAGEMENT.POS.POS") }}
          </b-button> -->

          <div class="d-flex align-items-end">
            <b-button
              v-b-modal.assign-modal
              class="mb-4"
              variant="success"
              :disabled="!hasAnySelectedItems"
            >
              <span v-if="hasAnySelectedItems" class="mr-1">
                {{ selectedRows.length }}
                {{ $t("PAGES.SALES_MANAGEMENT.POS.POS") }}
              </span>
              {{ $t(buttonTitle) }}
            </b-button>
          </div>
        </div>

        <DatePickers
          :filters="filters"
          @on-start-date-selected="onStartDateSelected"
          @on-end-date-selected="onEndDateSelected"
          @on-all-dates-selected="onAllDatesSelected"
        >
          <template v-slot:filters>
            <list-filter
              v-for="(filter, filterIndex) in filters"
              :key="filterIndex"
              :filter="filter"
              :filter-index="filterIndex"
              @option-selected="onOptionSelected"
            />
          </template>
        </DatePickers>

        <!-- <ListSearch
          v-if="showSearch"
          :search-name="$t('PAGES.SALES_MANAGEMENT.LIST.LEVELS.MERCHANT')"
          @filter-change="onFilterChange"
        /> -->

        <b-table
          ref="posTable"
          id="pos-table"
          primary-key="_id"
          :items="fetchData"
          :fields="tableFields"
          :filter="filter"
          :filter-included-fields="filterIncludedFields"
          selectable
          select-mode="multi"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @row-selected="onRowSelected"
          @row-clicked="onRowClicked"
          @refreshed="onRefreshed"
          responsive
        >
          <!-- <template #head(checked)="data"> -->
          <template #head(checked)>
            <div class="d-inline-flex">
              <b-form-checkbox
                id="select-all-checkbox"
                name="select-all-checkbox"
                v-model="allRowsChecked"
                value="true"
                unchecked-value="false"
                :disabled="!itemsCount"
                :indeterminate="indeterminateCheck"
                @change="onCheckAllChange"
              />
            </div>
          </template>

          <template #cell(checked)="data">
            <!-- v-if="!data.item.companyUri && !data.item.distributorUri" -->
            <div
              class="d-inline-flex"
              v-if="data && showItemCheckbox(data.item)"
            >
              <b-form-checkbox
                :id="`select-item-checkbox-${data.index}`"
                :name="`select-item-checkbox-${data.index}`"
                v-model="data.item.checked"
                unchecked-value="false"
                @change="onRowCheck(data)"
              />
            </div>
          </template>

          <template #cell(hasSystemDefined)="data">
            <span class="d-inline-flex align-items-center">
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.hasSystemDefined"
              ></b-icon>
              <b-icon
                icon="x"
                v-else
                font-scale="2"
                class="common-icon-color"
              ></b-icon>
            </span>
          </template>

          <template #cell(isActive)="data">
            <span class="d-inline-flex align-items-center">
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.isActive"
              ></b-icon>
              <b-icon
                icon="x"
                v-else
                font-scale="2"
                class="common-icon-color"
              ></b-icon>
            </span>
          </template>

          <template #cell(hasDelivered)="data">
            <span class="d-inline-flex align-items-center">
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.hasDelivered"
              ></b-icon>
              <b-icon
                icon="x"
                v-else
                font-scale="2"
                class="common-icon-color"
              ></b-icon>
            </span>
          </template>
        </b-table>

        <div class="row align-items-center mt-5">
          <div class="mt-5 col-12 pt-5">
            <b-pagination
              align="fill"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="orders-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      @ok="onOkClick"
      @show="onModalShow"
      id="assign-modal"
      :cancel-title="$t('FORM.CANCEL')"
      :ok-title="$t('FORM.OK')"
      :title="$t('FORM.ASSIGNMENT_CONFIRMATION')"
    >
      <b-container fluid>
        <div v-if="selectCompanies.length" class="b-form-group mb-5">
          <label for="input-company" class="col-form-label text-right">
            {{ $t("COMPANY.COMPANY") }}
          </label>
          <b-form-select
            v-model="selectCompanyId"
            :options="selectCompanies"
            text-field="title"
            value-field="id"
            id="input-company"
            name="input-company"
          >
            <template #first>
              <b-form-select-option :value="null">{{
                $t("FORM.PLEASE_SELECT")
              }}</b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import Api from "@/services/api";
// import ListSearch from "@/components/list/ListSearch.vue";
import ListFilter from "@/components/list/ListFilter.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";
// import SalesReportExport from "@/components/list/SalesReportExport.vue";

export default {
  components: {
    // ListSearch,
    ListFilter,
    DatePickers
    // VueHtml2pdf,
    // SalesReportExport
  },
  mixins: [ListMixin],
  data() {
    return {
      listData: [],
      //   filterIncludedFields: ["merchant.title"],
      filterIncludedFields: [],
      //   generatingPdf: false,
      //   exportItems: [],
      // fixedFilters: "status=1",
      allRowsChecked: false,
      selectedRows: [],
      selectCompanies: [],
      showSearch: true,
      selectCompanyId: null,
      filters: [
        // {
        //   text: "PAGES.SALES_MANAGEMENT.LIST.STATUS",
        //   field: "status",
        //   type: FILTER_TYPE.ORDER_STATUS,
        //   currentValue: undefined
        // },
        // {
        //   text: "PAGES.SALES_MANAGEMENT.LIST.PAYMENT",
        //   field: "paymentState.id",
        //   type: FILTER_TYPE.PAYMENT_STATE,
        //   currentValue: undefined
        // }
      ],
      distributorField: [
        {
          key: "distributorTitle",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.DISTRIBUTOR")
        }
      ],
      merchantField: [
        {
          key: "merchantTitle",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.MERCHANT")
        }
      ],
      branchField: [
        {
          key: "branchTitle",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.BRANCH")
        }
      ],
      fields: [
        {
          key: "checked",
          class: "text-center"
        },
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "model",
          label: "Model",
          sortable: true
        },
        {
          key: "serial",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.SERIAL"),
          class: "text-center",
          sortable: true
        },
        this.showDistributorField,
        this.showMerchantField,
        this.branchField,
        {
          key: "hasSystemDefined",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.SYSTEM_DEFINED"),
          class: "text-center",
          sortable: true
        },
        {
          key: "isActive",
          label: this.$t("ECOMMERCE.COMMON.ACTIVE"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "hasDelivered",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.DELIVERED"),
          class: "text-center",
          sortable: true
        },
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE"),
          class: "text-center",
          sortable: true,
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.SALES_MANAGEMENT.POS.POS") },
      { title: this.$t("ROUTES.POS_LIST") }
    ]);
  },
  computed: {
    ...mapGetters({
      activeUser: "activeUser",
      mainCompanyId: "company/MAIN_COMPANY_ID",
      isDistributor: "isDistributor",
      isMerchant: "isMerchant"
    }),
    fetchUrl() {
      return "pos";
    },
    fixedFilters() {
      const param = this.isDistributor ? "distributor.id" : "merchant.id";
      return `${param}=${this.mainCompanyId}`;
    },
    tableFields() {
      const startFields = [
        {
          key: "checked",
          class: "text-center"
        },
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "model",
          label: "Model",
          sortable: true
        },
        {
          key: "serial",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.SERIAL"),
          class: "text-center",
          sortable: true
        }
      ];

      const endFields = [
        {
          key: "hasSystemDefined",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.SYSTEM_DEFINED"),
          class: "text-center",
          sortable: true
        },
        {
          key: "isActive",
          label: this.$t("ECOMMERCE.COMMON.ACTIVE"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "hasDelivered",
          label: this.$t("PAGES.SALES_MANAGEMENT.POS.DELIVERED"),
          class: "text-center",
          sortable: true
        },
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE"),
          class: "text-center",
          sortable: true,
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        }
      ];

      return [...startFields, ...this.showDistributorFields, ...endFields];
    },
    showDistributorFields() {
      return this.isDistributor
        ? [...this.distributorField, ...this.merchantField, ...this.branchField]
        : [...this.merchantField, ...this.branchField];
    },
    unassignedPosList() {
      return this.listData.filter(posItem => {
        const hasAnyUri = posItem.merchantUri;
        return !hasAnyUri;
      });
    },
    buttonTitle() {
      const assignText = this.isDistributor
        ? "ASSIGN_TO_MERCHANT"
        : "ASSIGN_TO_BRANCH";
      return `PAGES.SALES_MANAGEMENT.POS.${assignText}`;
    },
    itemsCount() {
      return this.unassignedPosList.length;
    },
    hasAnySelectedItems() {
      return this.selectedRows.length > 0;
    },
    indeterminateCheck() {
      const selectedRowsCount = this.selectedRows.length;
      return selectedRowsCount > 0 && selectedRowsCount < this.itemsCount;
    },
    posTable() {
      return this.$refs.posTable;
    }
  },
  methods: {
    ...mapActions({
      setSelectedPos: "pos/SET_SELECTED_POS",
      clearSelectedPos: "pos/CLEAR_SELECTED_POS",
      togglePosActiveStatus: "pos/TOGGLE_POS_ACTIVE_STATUS",
      setPosesToAssign: "pos/SET_POSES_TO_ASSIGN"
    }),
    showItemCheckbox(item) {
      return this.isDistributor ? !item.merchantUri : !item.branchUri;
    },
    async onOkClick() {
      const url = `companies/${this.selectCompanyId}/assign-pos`;
      const payload = this.selectedRows.map(pos => {
        return { id: pos._id };
      });
      try {
        await Api.post(url, payload);
        this.posTable.refresh();
        this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
      } catch (err) {
        // console.log(err);
        const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
        this.$root.$bvToast.toast(msg, {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async onModalShow() {
      const fetchRole = this.isDistributor ? 3 : 4;
      const selectCompanies = await Api.get(
        `companies?parent.id=${this.mainCompanyId}&role.id=${fetchRole}`
      );
      this.selectCompanies = selectCompanies.data;
    },
    onCheckAllChange(checked) {
      const isChecked = checked == true || checked == "true";

      const checkAction = isChecked
        ? this.posTable.selectAllRows
        : this.posTable.clearSelected;
      checkAction();

      this.unassignedPosList.forEach(data => (data["checked"] = checked));
    },
    onRowCheck(data) {
      const checked = data.item.checked;
      const checkAction = checked == true ? data.selectRow : data.unselectRow;
      checkAction();
    },
    onRowClicked(item) {
      const pos = item;
      const action = pos ? this.setSelectedPos : this.clearSelectedPos;

      action(pos).then(() => {
        this.$router.push({ name: "pos-details" });
      });
    },
    onRowSelected(items) {
      let checkItems = [];

      items.forEach((item, index) => {
        // const hasAnyUri = item.companyUri ?? item.distributorUri;
        const hasAnyUri = this.isDistributor
          ? item.merchantUri
          : item.branchUri;
        if (hasAnyUri) {
          item.checked = false;
          this.posTable.unselectRow(index);
        } else {
          checkItems.push(item);
        }
      });

      this.selectedRows = items;
    },
    onRefreshed() {
      this.resetProperties();

      this.listData.forEach(data => (data["checked"] = false));
    },
    resetProperties() {
      this.allRowsChecked = false;
      this.selectedRows = [];
      this.selectCompanies = [];
      this.selectCompanyId = null;
    },
    preparePosLink() {
      this.clearSelectedPos();
      this.$router.push({ name: "pos-details" });
    }
    // async generatePDF() {
    //   const url = `company-orders/${this.mainCompanyId}/branch-sales-report`;
    //   const fetchedData = await Api.get(url);

    //   this.generatingPdf = true;
    //   this.exportItems = fetchedData.data;
    //   this.$refs.html2Pdf.generatePdf();
    // },
    // onProgress(event) {
    //   this.generatingPdf = event < 100;
    // }
  }
};
</script>

<style lang="scss" scoped>
.busy-spinner {
  width: 3rem;
  height: 3rem;
  margin: 5rem 0;
}
</style>
